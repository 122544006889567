import React from 'react';
import { P } from '../typography';

export const Back = ({ callback }: { callback: () => any }) => {
  return (
    <P className="bg-white mt-5 mb-10">
      <a
        href="#back"
        className="block hover:underline"
        onClick={e => {
          e.preventDefault();
          callback();
        }}
      >
        <img
          className="inline pb-1"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjQ1NDE1IDAuMDk5NDY3OUw0LjI0ODE1IDAuMDk5NDY3OEwwLjkxODE0OCAzLjg2MTQ3TDQuMjQ4MTUgNy42MDU0N0w1LjQ1NDE1IDcuNjA1NDdMMi42NDYxNSA0LjM4MzQ3TDE0LjIwMjEgNC4zODM0N0wxNC4yMDIxIDMuMzM5NDdMMi42MjgxNSAzLjMzOTQ3TDUuNDU0MTUgMC4wOTk0Njc5WiIgZmlsbD0iIzAwMDA0NiIvPgo8L3N2Zz4K"
        />{' '}
        Volver
      </a>
    </P>
  );
};
