import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CompanyContext } from '../../../../../context/company.context';
import { LangContext } from '../../../../../context/lang.context';
import {isConsecutive, isConsecutiveDown, isRepeated} from '../../../../../utils/functions';
import { H4 } from '../../../typography';

const PinValidation = ({ pinValue }: { pinValue: string }) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              SECURITY_PIN {
                PIN {
                  INFO {
                    MIN_LENGTH
                    NO_CONSECUTIVE
                    NO_REPEAT
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { getPublicURL } = useContext(CompanyContext);
  const pendingSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-pending.svg`);
  const doneSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-done.svg`);
  const errorSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-error.svg`);

  const minLengthSrcImage = (value: string) => {
    if (!value) {
      return pendingSvg;
    } else {
      return value.length < 4 ? errorSvg : doneSvg;
    }
  };

  const noConsecutiveSrcImage = (value: string) => {
    if (value.length < 4) {
      return pendingSvg;
    } else {
      return (isConsecutive(value) || isConsecutiveDown(value))? errorSvg : doneSvg;
    }
  };

  const noRepeatSrcImage = (value: string) => {
    if (value.length < 4) {
      return pendingSvg;
    } else {
      return isRepeated(value) ? errorSvg : doneSvg;
    }
  };

  return (
    <ul className="mt-10 text-sm text-primary">
      <li className="mb-2 flex gap-6">
        <img src={minLengthSrcImage(pinValue)} alt="SVG" />
        <H4>Mínimo 4 caracteres.</H4>
      </li>
      <li className="mb-2 flex gap-6">
        <img src={noConsecutiveSrcImage(pinValue)} alt="SVG" />
        <H4>{t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_CONSECUTIVE}</H4>
      </li>
      <li className="mb-2 flex gap-6">
        <img src={noRepeatSrcImage(pinValue)} alt="SVG" />
        <H4>{t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_REPEAT}</H4>
      </li>
    </ul>
  );
};

export { PinValidation };
