import React, { ChangeEvent, useRef } from 'react';
import { Switch } from '../../../switch/switch';
import { H4 } from '../../../typography';

export const NewsletterField = ({
  initialValue,
  handleChange,
  setFieldValue,
}: {
  initialValue: boolean;
  handleChange: (e: ChangeEvent) => any;
  setFieldValue: (arg1: string, arg2: string) => any;
}) => {
  const newsletter = useRef<boolean>(initialValue);
  return (
    <Switch
      id="newsletter"
      checked={newsletter.current}
      onChange={(e: ChangeEvent<any>) => {
        handleChange(e);
        newsletter.current = e.currentTarget.checked;
        setFieldValue('newsletter', e.currentTarget.checked);
      }}
    >
      <H4 className="pr-11 text-primary">Acepto recibir información comercial en mi correo</H4>
    </Switch>
  );
};
