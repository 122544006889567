import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { AddFieldProps } from '../types';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { getMask } from '../utils/format';
import { CompanyContext } from '../../../../context/company.context';
import { getText } from '../../../../utils/functions';
import { createMask } from '../../../../utils/masks';
import { FieldLabel } from '../field-label';

export const TributeField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t, lang } = useContext(LangContext);
  const { clientId, tributeValidationType } = useContext(CompanyContext);

  const data = useStaticQuery(graphql`
    query {
      allGovernmentTributeService {
        nodes {
          clientId
          locale
          TRIBUTE_TYPE {
            id
            regex
            value
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              TRIBUTE {
                TYPE {
                  REQUIRED
                  LABEL
                  PLACEHOLDER
                  CUIL
                  CUIT
                }
                NUMBER {
                  REQUIRED
                  VALID
                  LABEL
                }
                HEADING
              }
            }
          }
        }
      }
    }
  `);

  const getRegex = (): string =>
    getText(data.allGovernmentTributeService.nodes, lang, clientId)
      .TRIBUTE_TYPE.find((e: any) => e?.id === formik?.values.tributeType)
      ?.regex?.slice(1, -1);

  const tributeTypes = getText(data.allGovernmentTributeService.nodes, lang, clientId).TRIBUTE_TYPE;
  const valueType = formik?.values.tributeType;
  const tributeTypeField = fields?.tributeType!;
  const tributeNumberField = fields?.tributeNumber!;

  if (tributeTypeField?.required || tributeTypeField?.defaultValue) {
    schemas.tributeType = Yup.string().required(t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.TYPE.REQUIRED);
  }
  if (tributeValidationType === 'CUIT-CUIL') {
    schemas.tributeNumber = Yup.string()
      .required(t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.TYPE.REQUIRED)
      .validCuitCuil(t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.NUMBER.VALID);
  }
  const regex = getRegex();
  const mask: RegExp[] = getMask(valueType, regex);

  return (
    <div key="tribute" className="mb-5 mt-10">
      <FieldLabel heading={t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.HEADING} required={tributeTypeField?.required} />
      <div className="flex gap-4">
        <div className="w-1/3">
          <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.TYPE.LABEL}</label>
          <select
            defaultValue={formik?.values.tributeType}
            onChange={formik?.handleChange('tributeType')}
            className={`input ${formik?.errors.tributeType && 'border-red'} ${
              tributeTypeField?.readOnly && 'bg-gray-200'
            }`}
            disabled={tributeTypeField?.readOnly}
          >
            <option value="" disabled>
              {t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.TYPE.PLACEHOLDER}
            </option>
            {tributeTypes.map(({ value, id }: any) => (
              <option key={value} value={id}>
                {value}
              </option>
            ))}
          </select>
          {formik?.errors.tributeType && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.tributeType}
            </p>
          )}
        </div>
        <div className="w-2/3">
          <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.NUMBER.LABEL}</label>
          <MaskedInput
            value={formik?.values.tributeNumber}
            onChange={formik?.handleChange('tributeNumber')}
            className={`input ${formik?.errors.tributeNumber && 'border-red'} ${
              tributeNumberField?.readOnly && 'bg-gray-200'
            }`}
            mask={tributeValidationType === 'CUIT-CUIL' ? createMask('NUMBER', 11) : mask}
            guide={false}
            disabled={tributeNumberField?.readOnly}
          />
          {formik?.errors.tributeNumber && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.tributeNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
