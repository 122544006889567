import { Fields } from '../types';

/**
 * Convert APP to OutgoingDTO
 */
export type OutgoingDTO = {
  form_data: {
    customer: CustomerDTO;
  };
};

type CustomerDTO = {
  address?: {
    city?: string;
    department?: string;
    floor?: string;
    number?: string;
    province?: string;
    street?: string;
    zip_code?: string;
  };
  cell_phone?: string;
  email?: string;
  gender?: string;
  name?: string;
  pin?: {
    value: string;
    recaptcha: {
      response: any;
    };
  };
  surname?: string;
  telephone_company?: string;
  telephone_number?: string;
  tribute_key?: string;
  tribute_key_type?: string;
  government_identification?: string;
  government_identification_type?: string;
  biometrics?: boolean;
  ocr?: boolean;
  birth_date?: string;
  nationality?: string;
  terms_and_conditions?: boolean;
  additional_data?: {
    field_name: string;
    value: any;
  }[];
  alias?: string;
};

export function parse(data: Fields<any>, recaptchaToken?: any): OutgoingDTO {
  const customer: CustomerDTO = {};
  const fieldsMap = new Map<string, string>([
    ['firstName', 'name'],
    ['lastName', 'surname'],
    ['documentNumber', 'government_identification'],
    ['documentType', 'government_identification_type'],
    ['birthDate', 'birth_date'],
    ['gender', 'gender'],
    ['tributeNumber', 'tribute_key'],
    ['tributeType', 'tribute_key_type'],
    ['email', 'email'],
    ['phone', 'cell_phone'],
    ['alias', 'alias'],
  ]);

  // construct customer DTO based on fields map
  Object.keys(data).forEach(propName => {
    if (fieldsMap.has(propName)) {
      customer[fieldsMap.get(propName)] = data[propName];
    }
  });

  // address special props that need additional check and/or different structure
  customer.pin = parsePin(data, recaptchaToken);
  customer.terms_and_conditions = parseAgreement(data);
  customer.additional_data = parseAdditionalData(data);

  return {
    form_data: {
      customer,
    },
  };
}

const parsePin = (data: Fields<any>, recaptchaToken: string) => {
  if (data.pin && recaptchaToken) {
    return {
      value: data.pin,
      recaptcha: {
        response: recaptchaToken,
      },
    };
  }
};

const parseAgreement = (data: Fields<any>) => {
  if (data.agreement) {
    return data.agreement === 'true' ? true : false;
  }
};

const parseAdditionalData = (data: Fields<any>) => {
  const additionalData = [];
  if (data.occupation !== undefined) {
    additionalData.push({
      field_name: 'OCCUPATION',
      value: data.occupation,
    });
  }
  if (data.newsletter !== undefined) {
    additionalData.push({
      field_name: 'NEWSLETTER',
      value: data.newsletter,
    });
  }
  return additionalData.length > 0 ? additionalData : undefined;
};
