import React from 'react';

const Stepper = ({ step }: { step?: number }) => (
  <div className="static mt-5 -mb-5 flex w-full items-center justify-center bg-white xs:max-w-md">
    <div className="flex justify-between gap-[10px]">
      {Array.of(1, 2, 3, 4).map(i => (
        <div key={i} className={`${i <= step ? 'bg-primary' : 'bg-gray-300'} h-2 w-[50px] flex-1 rounded-full`}></div>
      ))}
    </div>
  </div>
);

export { Stepper };
