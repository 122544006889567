import React, { ChangeEvent } from 'react';

type SwitchProps = {
  id: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent) => any;
  toTop?: boolean;
  disabled?: boolean;
  children?: any;
};

const Switch = ({ id, checked, onChange, toTop, disabled, children }: SwitchProps) => {
  return (
    <label htmlFor={id} className={`flex justify-between ${toTop ? 'items-start' : 'items-center'} mb-[10px]`}>
      {children}
      <div
        className={`relative inline-block h-8 w-14 shrink-0 rounded-full ${
          checked ? 'bg-green-cta' : 'bg-neutral-300'
        } transition-color [width:53px] focus-within:outline focus-within:outline-2 focus-within:outline-cyan ${
          disabled ? 'opacity-50' : 'opacity-100'
        }`}
      >
        <div
          className={`absolute top-0.5 left-0.5 ${
            checked ? '[transform:translateX(21px)]' : 'translate-x-0'
          } h-7 w-7 rounded-full shadow-md transition-transform ${disabled ? 'bg-gray-100' : 'bg-white'}`}
        ></div>
        <input
          className="absolute left-1/2 -translate-x-1/2 opacity-0"
          type="checkbox"
          id={id}
          name={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </label>
  );
};

export { Switch };
