import React, { useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { AddFieldProps } from '../types';
import { createMask } from '../../../../../utils/masks';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../../../context/lang.context';
import { Field } from '../../field/field';

export const LastNameField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              LAST_NAME {
                HEADING
                REQUIRED
              }
            }
          }
        }
      }
    }
  `);
  const lastNameField = fields?.lastName!;

  if (lastNameField.required || lastNameField.defaultValue) {
    schemas.lastName = schemas?.lastName!.required(t(data).DYNAMIC_USER_FORM.FIELDS.LAST_NAME.REQUIRED);
  }

  return (
    <Field
      key="lastName"
      label="Apellidos"
      required={lastNameField?.required}
      warning={formik?.errors.lastName}
      readOnly={lastNameField?.readOnly}
    >
      <MaskedInput
        value={formik?.values.lastName}
        onChange={formik?.handleChange('lastName')}
        className="w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        mask={createMask('NAME', 40)}
        guide={false}
        disabled={lastNameField?.readOnly}
      />
    </Field>
  );
};
