import { AddFieldProps } from '../types';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../../../context/company.context';
import { LangContext } from '../../../../../context/lang.context';
import { Field } from '../../field/field';

export const GenderField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { clientId } = useContext(CompanyContext);
  const { t, lang } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              GENDER {
                HEADING
                REQUIRED
              }
            }
          }
        }
      }
      allGenderService {
        nodes {
          locale
          clientId
          GENDER_TYPE {
            id
            value
          }
        }
      }
    }
  `);

  const genderList = data.allGenderService.nodes.find(
    node => node.clientId === clientId && node.locale === lang
  ).GENDER_TYPE;

  const genderField = fields?.gender!;
  if (genderField?.required) {
    schemas.gender = schemas?.gender!.required(t(data).DYNAMIC_USER_FORM.FIELDS.GENDER.REQUIRED);
  }

  return (
    <Field
      key="gender"
      label={t(data).DYNAMIC_USER_FORM.FIELDS.GENDER.HEADING}
      required={genderField?.required}
      warning={formik?.errors.gender}
      readOnly={genderField?.readOnly}
    >
      <select
        className="w-full appearance-none bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        value={formik?.values.gender ?? ''}
        onChange={formik?.handleChange('gender') || (() => ({}))}
        disabled={genderField?.readOnly}
      >
        <option value="" disabled>
          Seleccionar género
        </option>
        {genderList.map(({ id, value }: { id: string; value: string }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </select>
    </Field>
  );
};
