import React, { useRef, ChangeEvent } from 'react';
import { PrivacyField } from './privacy';
import { TermsField } from './terms';

export const AgreementField = ({
  handleChange,
  setDisabled,
  setFieldValue,
}: {
  handleChange: (e: ChangeEvent) => any;
  setDisabled: (arg1: boolean) => any;
  setFieldValue: (arg1: string, arg2: string) => any;
}) => {
  const agreement = useRef<boolean[]>([false, false]);
  const isAgreementComplete = () => agreement.current.every(i => i === true);

  const onTermsFieldChange = (e: ChangeEvent<any>) => {
    handleChange(e);
    agreement.current[0] = e.currentTarget.checked;
    setDisabled(!isAgreementComplete());
    if (isAgreementComplete()) {
      setFieldValue('agreement', 'true');
    }
  };
  const termsFieldValue = agreement.current[0];

  const onPrivacyFieldChange = (e: ChangeEvent<any>) => {
    handleChange(e);
    agreement.current[1] = e.currentTarget.checked;
    setDisabled(!isAgreementComplete());
    if (isAgreementComplete()) {
      setFieldValue('agreement', 'true');
    }
  };
  const privacyFieldValue = agreement.current[1];

  return (
    <section>
      <TermsField checked={termsFieldValue} onChange={onTermsFieldChange} />
      <PrivacyField checked={privacyFieldValue} onChange={onPrivacyFieldChange} />
    </section>
  );
};
