import React, { ChangeEvent, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Switch } from '../../../switch/switch';
import { H4 } from '../../../typography';
import { Content } from '../../../content';

export const TermsField = ({
  checked,
  onChange,
  disabled,
}: {
  checked: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
}) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/content/terms/" }) {
        html
      }
    }
  `);
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  return (
    <>
      <Switch id="terms" checked={checked} onChange={onChange} disabled={disabled}>
        <H4 className="pr-11 text-primary">
          He leído y acepto los{' '}
          <a href="#" onClick={() => setContentVisible(true)} className="inline underline">
            Términos y condiciones
          </a>{' '}
          del contrato de servicios con Silbo*
        </H4>
      </Switch>
      {contentVisible && (
        <div className="fixed left-0 top-0 z-100 w-full">
          <Content content={data.markdownRemark.html} onBack={() => setContentVisible(false)} />
        </div>
      )}
    </>
  );
};
