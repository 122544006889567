import React from 'react';
import MaskedInput from 'react-text-mask';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';

export const BirthDateField = ({ fields, formik }: AddFieldProps) => {
  const birthDateField = fields?.birthDate!;
  return (
    <Field
      key="birthDate"
      label="Fecha de Nacimiento"
      required={birthDateField?.required}
      warning={formik?.errors.birthDate}
      readOnly={birthDateField?.readOnly}
    >
      <MaskedInput
        value={formik?.values.birthDate}
        onChange={formik?.handleChange('birthDate')}
        className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar="-"
        showMask={true}
        inputMode="numeric"
        disabled={birthDateField?.readOnly}
      />
    </Field>
  );
};
