import React from 'react';

const FieldLabel = ({ heading, sideNote, required }: { heading: string; sideNote?: string; required?: boolean }) => (
  <label className="block font-bold mb-3">
    {heading}
    {required && <span className="font-normal">*</span>}
    {sideNote && <span className="text-xs ml-2 font-normal">{sideNote}</span>}
  </label>
);

export { FieldLabel };
