import React from 'react';
import { H2, P } from '@/components/287634/typography';

const StepResetPin = () => (
  <section className="relative mx-5 flex flex-col items-center text-primary">
    <H2 className="mb-5 mt-[60px] text-center">Nuevo código de seguridad</H2>
  </section>
);

export { StepResetPin };
