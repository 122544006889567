import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../components/287634/layout/layout';
import { Back } from '../../../components/287634/content/back';

export const Content = ({ content, onBack }: { content: string; onBack?: () => void }) => {
  return (
    <Layout>
      <section className="mx-auto w-full max-w-md px-5 text-lg leading-6 text-primary">
        <Back
          callback={() => {
            if (onBack) {
              onBack();
            } else {
              navigate(-1);
            }
          }}
        />
        <article
          className="prose prose-primary overflow-hidden pb-8 text-primary prose-headings:font-medium prose-headings:text-primary prose-a:font-normal prose-a:text-primary prose-strong:font-medium prose-strong:text-primary prose-em:not-italic"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </section>
    </Layout>
  );
};
