import React, { useContext, useState, useEffect } from 'react';
import { AddFieldProps } from '../types';
import { hasRegisteredCredentials, register, removeRegistrationsByCompanyId, supports } from '../../../../utils/webauthn';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../../../context/lang.context';
import { CompanyContext } from '../../../../context/company.context';

export const BiometricsField = ({ token }: AddFieldProps) => {
    return <BiometricsCheckbox key="biometrics" token={token} />;
};

export const BiometricsCheckbox = ({ token }: { token: any }) => {
  const { t } = useContext(LangContext);
  const { clientId } = useContext(CompanyContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              BIOMETRICS {
                LABEL
              }
            }
          }
        }
      }
    }
  `);

  const [biometricsSupport, setBiometricsSupport] = useState(false);
  const [biometricsAdoption, setBiometricsAdoption] = useState(false);

  const onChange = (event: any) => {
    if (event.target.checked) {
      console.log('Biometrics authentication enabled.');
      register(token, clientId)
        .then(() => {
          // @todo: UI feedback: notify user about biometrics auth state
          setBiometricsAdoption(true);
        })
        .catch(error => {
          // @todo: UI feedback: notify user about any failures
          console.error({error});
        });
    } else {
      console.log('Biometrics authentication disabled.');
      // remove all registrations relative to the current company
      removeRegistrationsByCompanyId(clientId);
      setBiometricsAdoption(false);
    }
  };

  useEffect(() => {
    supports()
    .then(() => {
      console.log('Biometrics supported.');
      setBiometricsSupport(true);
      if (hasRegisteredCredentials(clientId)) {
        console.log('Biometrics already adopted.')
        setBiometricsAdoption(true);
      }
    })
    .catch(() => console.warn('PublicKeyCredential API not available.'));
  }, [])

  if (biometricsSupport) {
    return (
      <div className="text-sm text-gray-800 leading-relaxed mb-5 p-3 border border-gray-400 rounded">
        <label htmlFor="biometrics" className="flex">
          <input
            type="checkbox"
            id="biometrics"
            name="biometrics"
            className="mr-2 mt-1"
            checked={biometricsAdoption}
            onChange={onChange}
          />
          <span>{t(data).DYNAMIC_USER_FORM.FIELDS.BIOMETRICS.LABEL}</span>
        </label>
      </div>
    );
  }
  return <div />;
};
