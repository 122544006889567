import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';
import { AddFieldProps } from '../types';

export const PhoneField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              PHONE {
                HEADING
                REQUIRED
              }
            }
          }
        }
      }
    }
  `);
  const phoneField = fields?.phone!;

  if (phoneField?.required || phoneField?.defaultValue) {
    schemas.phone = schemas?.phone!.required(t(data).DYNAMIC_USER_FORM.FIELDS.PHONE.REQUIRED);
  }

  return (
    <div key="phone" className="mb-5 mt-10">
      <FieldLabel heading={t(data).DYNAMIC_USER_FORM.FIELDS.PHONE.HEADING} required={phoneField?.required} />
      <input
        value={formik?.values.phone}
        onChange={formik?.handleChange('phone')}
        className={`input ${formik?.errors.phone && 'border-red'}`}
      />
      {formik?.errors.phone && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.phone}
        </p>
      )}
    </div>
  );
};
