import React from 'react';
import { Stepper } from '@/components/287634/stepper/stepper';
import { H2, H3, P } from '@/components/287634/typography';

const StepData = () => (
  <section className="relative mx-5 flex flex-col items-center text-primary">
    <Stepper step={4} />
    <H2 className="mb-5 mt-[68px] text-center">Datos personales</H2>
    <H3 className="mb-5 text-center">¡Estás a un paso de poder enviar dinero por WhatsApp!</H3>
    <P className="mb-8 text-center">
      Tus datos personales están 100% protegidos por nosotros. Puedes confirmarlos, modificarlos y completarlos a
      continuación. Lo único que no podrás es modificar tu DNI.
    </P>
  </section>
);

export { StepData };
