import React from 'react';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';

export const OccupationField = ({ fields, formik, schemas }: AddFieldProps) => {
  const occupationList = [
    {
      id: 'Estudiante',
      value: 'Estudiante',
    },
    {
      id: 'Empleado/a',
      value: 'Empleado/a',
    },
    {
      id: 'Autónomo/a',
      value: 'Autónomo/a',
    },
    {
      id: 'Actualmente desempleado/a',
      value: 'Actualmente desempleado/a',
    },
  ];

  const occupationField = fields?.occupation!;

  if (occupationField?.required || occupationField?.defaultValue) {
    schemas.occupation = schemas?.occupation!.required('El campo Ocupación es requerido.');
  }

  return (
    <Field
      key="occupation"
      label="Ocupación"
      required={occupationField?.required}
      warning={formik?.errors.occupation}
      readOnly={occupationField?.readOnly}
    >
      <select
        className="w-full appearance-none bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
        value={formik?.values.occupation ?? ''}
        onChange={formik?.handleChange('occupation') || (() => ({}))}
        disabled={occupationField?.readOnly}
      >
        <option value="" disabled>
          Seleccionar ocupación
        </option>
        {occupationList.map(({ id, value }: { id: string; value: string }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </select>
    </Field>
  );
};
