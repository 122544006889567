import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { compareAsc, format, isValid, parse, sub } from 'date-fns';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { Config, Command, ConfigFields, FieldName, Fields, SchemaFields } from './types';
import { FirstNameField } from './fields/first-name';
import { LastNameField } from './fields/last-name';
import { BirthDateField } from './fields/birth-date';
import { DocumentField } from './fields/document';
import { GenderField } from './fields/gender';
import { OccupationField } from './fields/occupation';
import { EmailField } from './fields/email';
import { TributeField } from '../../../forms/dynamic-user/fields/tribute';
import { PinField } from './fields/pin';
import { BiometricsField } from '../../../forms/dynamic-user/fields/biometrics';
import { AgreementField } from './fields/agreement';
import { PhoneField } from '../../../forms/dynamic-user/fields/phone';
import { AliasField } from '../../../forms/dynamic-user/fields/alias';
import { NewsletterField } from './fields/newsletter';
import { getText, isTrue } from '../../../../utils/functions';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../../context/lang.context';
import Button from '../../button/button';
import { H4 } from '../../typography';
import { CompanyContext } from '../../../../context/company.context';
import { returnWhatsapp } from '../../../../utils/browser';

type Props = {
  config: Config;
  command: Command;
  customerStatus?: string;
  submit: (values: Fields<string>) => void;
  token: string;
  isAliasError: boolean;
  isResponseFinished: boolean;
  isPinError: 'repeated' | 'userBirthDate' | undefined;
};

export const DynamicUserForm = ({
  config,
  command,
  customerStatus,
  submit,
  token,
  isAliasError,
  isPinError,
  isResponseFinished,
}: Props) => {
  const { t, lang } = useContext(LangContext);
  const { clientId, tributeValidationType } = useContext(CompanyContext);
  const data = useStaticQuery(graphql`
    query {
      allGovernmentIdentificationService {
        nodes {
          clientId
          locale
          DOCUMENT_TYPE {
            id
            regex
            value
          }
        }
      }
      allGovernmentTributeService {
        nodes {
          clientId
          locale
          TRIBUTE_TYPE {
            id
            regex
            value
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                NUMBER {
                  INVALID
                }
              }
              TRIBUTE {
                NUMBER {
                  CUIT {
                    INVALID
                  }
                  CUIL {
                    INVALID
                  }
                  VALID
                }
              }
              ALIAS {
                ALREADY_EXIST
              }
              SECURITY_PIN {
                PIN_REPEATED
                PIN_USER_BIRTH_DATE
                PIN {
                  REQUIRED
                }
              }
            }
            SUBMIT {
              SAVE
            }
          }
        }
      }
    }
  `);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [formIsDirty, setFormIsDirty]  = useState<boolean>(false);

  const addFieldFunctions: Fields<(props: any) => void> = {
    firstName: FirstNameField,
    lastName: LastNameField,
    documentNumber: DocumentField,
    gender: GenderField,
    occupation: OccupationField,
    tributeNumber: TributeField,
    pin: PinField,
    biometrics: BiometricsField,
    email: EmailField,
    phone: PhoneField,
    alias: AliasField,
  };
  useEffect(() => {
    // @todo: do we need this at all?
    if (
      config.fields.agreement?.defaultValue === 'true' ||
      (config.fields.pin && Object.keys(fields).length === 1) ||
      !Object.keys(fields).some(item => 'pin' === item || 'agreement' === item) ||
      // in case T&C control is not present, we assume the form should be enabled for submit
      !fields?.agreement
    ) {
      setDisabled(false);
    }
  }, []);

  const { fields: rawFields, allRequired } = config;

  const fields: ConfigFields = { ...rawFields };
  const schemas: SchemaFields = {};
  const initialValues: Fields<string> = {};

  for (const k in fields) {
    const key = k as FieldName;
    const field = fields[key]!;
    if (allRequired) {
      field.required = true;
    }
    initialValues[key] = field.defaultValue === undefined ? '' : field.defaultValue;

    // prepare birth date format for field; if a default value is passed,
    // it will be formatted to satisfy
    if (key === 'birthDate' && field.defaultValue) {
      initialValues['birthDate'] = format(parse(field.defaultValue, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
    }
    schemas[key] = schemas[key] || Yup.string();
    addFieldFunctions[key]?.({ fields, schemas, initialValues, setDisabled });
  }

  const validate = (values: any) => {
    setFormIsDirty(true);
    const errors: any = {};
    try {
      if (tributeValidationType === 'NIF-NIE') {
        const getRegex1 = (): string =>
          getText(data.allGovernmentIdentificationService.nodes, lang, clientId)
            .DOCUMENT_TYPE.find((e: any) => e?.id === formik?.values.documentType)
            ?.regex?.slice(1, -1);

        const getRegex2 = (): string =>
          getText(data.allGovernmentTributeService.nodes, lang, clientId)
            .TRIBUTE_TYPE.find((e: any) => e?.id === formik?.values.tributeType)
            ?.regex?.slice(1, -1);

        const regex1 = new RegExp(getRegex1());
        const regex2 = new RegExp(getRegex2());
        if (!regex1.test(values.documentNumber)) {
          if (formik.values.documentType === 'DNI') {
            errors.documentNumber = t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.INVALID;
          }
          if (formik.values.documentType === 'NIE') {
            errors.documentNumber = t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.NUMBER.CUIT.INVALID;
          }
        }
        if (!regex2.test(values.tributeNumber)) {
          if (formik.values.tributeType === 'NIE') {
            errors.tributeNumber = t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.NUMBER.CUIT.INVALID;
          }
          if (formik.values.tributeType === 'NIF') {
            errors.tributeNumber = t(data).DYNAMIC_USER_FORM.FIELDS.TRIBUTE.NUMBER.CUIL.INVALID;
          }
        }
      }
      if (values.birthDate) {
        // additional birth date validation: valid date, and age check
        if (!isValid(parse(values.birthDate, 'dd/MM/yyyy', new Date()))) {
          errors.birthDate = 'Fecha de nacimiento inválida.';
        }
        if (compareAsc(parse(values.birthDate, 'dd/MM/yyyy', new Date()), sub(new Date(), { years: 18 })) > 0) {
          errors.birthDate = '¡Debes ser mayor de edad para poder operar con Silbo!';
        }
      }
    } catch (error: any) {
      console.log(error);
    }
    return errors;
  };

  const formik = useFormik<Fields<any>>({
    initialValues,
    validateOnChange: formIsDirty,
    validateOnBlur: false,
    validationSchema: Yup.object().shape(schemas),
    validate,
    onSubmit(values: any) {
      if (values.documentNumber !== undefined && values.documentNumber.length < 8) {
        values.documentNumber = '0' + values.documentNumber;
      }
      if (values.birthDate) {
        // convert birth date back to original ISO format
        values.birthDate = format(parse(values.birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
      }
      submit(values);
    },
  });

  useEffect(() => {
    if (isAliasError) {
      formik.setFieldError('alias', t(data).DYNAMIC_USER_FORM.FIELDS.ALIAS.ALREADY_EXISTS);
    }
    if (isPinError === 'repeated') {
      formik.setFieldError('pin', t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_REPEATED);
    }
    if (isPinError === 'userBirthDate') {
      formik.setFieldError('pin', t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_USER_BIRTH_DATE);
    }
  }, [isAliasError, isPinError]);

  useEffect(() => {
    // if document number is pre-filled but document type is a required field and comes empty, present a warning
    if (
      !isEmpty(formik?.values.documentNumber) &&
      config.fields.documentType?.required &&
      isEmpty(formik?.values.documentType)
    ) {
      // @todo translate string
      formik.setFieldError('documentType', 'Requerido');
    } else {
      formik.setFieldError('documentType', undefined);
    }
  }, [formik?.values.documentType]);

  const hasValue = [];

  for (const k in fields) {
    const key = k as FieldName;
    if (fields[key]?.required) {
      hasValue.push(!!formik.values[key]);
    }
  }

  const notValid = ![...hasValue, !disabled, isResponseFinished].every(isTrue);

  // @note: need to check for "not true", because value could come as `null` as well
  const agreementField = fields?.agreement && fields?.agreement!.required && fields?.agreement!.defaultValue !== 'true';

  return (
    <form className="mx-auto mt-0 mb-auto px-5" onSubmit={formik.handleSubmit} noValidate>
      {fields?.firstName && <FirstNameField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.lastName && <LastNameField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.birthDate && <BirthDateField fields={fields} formik={formik} />}
      {fields?.documentType && fields?.documentNumber && (
        <DocumentField fields={fields} formik={formik} schemas={schemas} />
      )}
      {fields?.gender && <GenderField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.occupation && <OccupationField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.tributeType && fields?.tributeNumber && (
        <TributeField fields={fields} formik={formik} schemas={schemas} />
      )}
      {fields?.alias && <AliasField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.phone && <PhoneField fields={fields} formik={formik} schemas={schemas} />}
      {fields?.pin && <PinField fields={fields} formik={formik} schemas={schemas} initialValues={initialValues} />}
      {fields?.pin && command !== 'dynamic_onboarding' && command !== 'dynamic_reset_pin' && (
        <BiometricsField fields={fields} formik={formik} schemas={schemas} token={token} />
      )}
      {fields?.email && <EmailField fields={fields} formik={formik} schemas={schemas} />}

      {agreementField && (
        <AgreementField
          handleChange={formik?.handleChange}
          setDisabled={setDisabled}
          setFieldValue={formik?.setFieldValue}
        />
      )}

      {customerStatus !== undefined &&
        command !== 'dynamic_reset_pin' &&
        command !== 'dynamic_onboarding' && (
        <H4 className="mb-[10px] text-gray-400">*Campos obligatorios</H4>
      )}

      {fields?.newsletter && (
        <NewsletterField
          initialValue={fields?.newsletter.defaultValue}
          handleChange={formik?.handleChange}
          setFieldValue={formik?.setFieldValue}
        />
      )}

      {!formik.isValid && (
        <div className="text-center text-xs text-red mb-5">Hay errores en el formulario. Por favor, revisa tus datos.</div>
      )}

      {command !== 'dynamic_onboarding' && command !== 'dynamic_reset_pin' && (
        <button
          className="mx-auto mb-4 flex text-base text-primary underline hover:text-gray"
          type="button"
          onClick={() => returnWhatsapp()}
        >
          Volver a WhatsApp
        </button>
      )}
      <Button id="submit-button" type="submit" disabled={notValid} className="mx-auto mt-15" >
        {t(data).DYNAMIC_USER_FORM.SUBMIT.SAVE}
      </Button>
    </form>
  );
};
