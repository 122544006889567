import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../../../context/lang.context';
import { AddFieldProps } from '../types';
import { Field } from '../../field/field';
import { H4 } from '../../../typography';

export const EmailField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              EMAIL {
                HEADING
              }
            }
          }
          USER_DETAIL {
            FORM {
              VALIDATION {
                EMAIL_FORMAT_NOT_VALID
              }
            }
          }
        }
      }
    }
  `);

  const emailField = fields?.email!;

  schemas.email = schemas?.email!.email(t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID);

  if (emailField?.required || emailField?.defaultValue) {
    schemas.email = schemas?.email
      .required(t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID)
      .matches(
        /^[a-zA-Z0-9._+&*-]+@[a-zA-Z0-9._+&*-]+\.[a-zA-Z]{2,7}$/,
        t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID
      );
  }

  return (
    <section>
      <Field
        key="email"
        label={t(data).DYNAMIC_USER_FORM.FIELDS.EMAIL.HEADING}
        required={emailField?.required}
        warning={formik?.errors.email}
        readOnly={emailField?.readOnly}
      >
        <input
          value={formik?.values.email}
          onChange={formik?.handleChange('email')}
          className="w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
          maxLength={65}
          type="email"
        />
      </Field>
      <H4 className="mb-5 text-gray-400">Por favor, revisa tu correo y verifica tu email.</H4>
    </section>
  );
};
