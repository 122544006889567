import { useStaticQuery, graphql } from 'gatsby';
import { useContext, useState } from 'react';
import { CompanyContext } from '../context/company.context';
import { LangContext } from '../context/lang.context';

type Status = {
  title: string;
  image: string;
  notePrefix?: string;
  noteSuffix?: string;
  error?: boolean;
  whatsapp?: string;
};

export type StatusProps = {
  statusType: 'success' | 'failed' | 'expired';
  intent?: string;
  communicationToken?: string;
};

export const useStatus = ({ statusType, intent, communicationToken }: StatusProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            STATUS {
              FAILED {
                NOTE_PREFIX
                NOTE_SUFFIX
                TITLE
                WHATSAPP
              }
              SUCCESS {
                TITLE
              }
              EXPIRED {
                TITLE
              }
            }
          }
        }
      }
    }
  `);

  const company = useContext(CompanyContext);
  const StatusSuccess = `/companies/${company.clientId}/theme/assets/images/feedback/generic-success.svg`;
  const StatusFailed = `/companies/${company.clientId}/theme/assets/images/error/generic-error.svg`;
  const StatusExpired = `/companies/${company.clientId}/theme/assets/images/error/generic-error.svg`;
  const baseStatus = {
    notePrefix: t(data).DYNAMIC_USER_FORM.STATUS.FAILED.NOTE_PREFIX,
    noteSuffix: t(data).DYNAMIC_USER_FORM.STATUS.FAILED.NOTE_SUFFIX,
    whatsapp: intent || t(data).DYNAMIC_USER_FORM.STATUS.FAILED.WHATSAPP,
    error: true,
  };
  const statusScenes: { [key: string]: Status } = {
    success: {
      title: t(data).DYNAMIC_USER_FORM.STATUS.SUCCESS.TITLE,
      image: StatusSuccess,
      // if a `communication token` is passed, then the `intent` is NOT passed to whatsapp
      whatsapp: !communicationToken ? intent : undefined,
    },
    failed: {
      ...baseStatus,
      title: t(data).DYNAMIC_USER_FORM.STATUS.FAILED.TITLE,
      image: StatusFailed,
    },
    expired: {
      ...baseStatus,
      title: t(data).DYNAMIC_USER_FORM.STATUS.EXPIRED.TITLE,
      image: StatusExpired,
    },
  };

  const [newStatusType, setStatusType] = useState<string>(statusType);

  return {
    status: statusScenes[newStatusType],
    setStatus: setStatusType,
  };
};
