import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as Yup from 'yup';
import { AddFieldProps } from '../types';
import { LangContext } from '../../../../../context/lang.context';
import { AnyObject } from 'yup/lib/types';
import { Pin } from '../../input/pin';
import {isConsecutive, isConsecutiveDown, isRepeated} from '../../../../../utils/functions';
import { PinValidation } from '../sections/pin-validation';
import { H3, H4 } from '../../../typography';

export const PinField = ({ fields, schemas, formik, initialValues }: AddFieldProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              SECURITY_PIN {
                PIN {
                  INFO {
                    MIN_LENGTH
                    NO_CONSECUTIVE
                    NO_REPEAT
                  }
                  REQUIRED
                }
                PIN_CONFIRM {
                  REQUIRED
                  MATCH
                }
              }
            }
          }
        }
      }
    }
  `);

  const pinField = fields?.pin!;

  schemas.pin = (schemas?.pin! as Yup.StringSchema<string, AnyObject, string>)
    .test(
      'not-consecutive',
      'No ingreses números consecutivos',
      (value: string) => (!isConsecutive(value) && !isConsecutiveDown(value))
    )
    .test(
      'not-repeated',
      'No ingreses números repetidos',
      (value: string) => !isRepeated(value)
    )
    .min(4, 'Ingresa 4 caracteres numéricos');

  if (pinField?.required) {
    schemas.pin = schemas?.pin.required(t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.REQUIRED);
  }

  initialValues.confirmPin = initialValues?.pin;
  schemas.confirmPin = Yup.string().when('pin', (pin: string, schema: Yup.StringSchema) => {
    if (pin && pin.length) {
      return schema
        .required('No ingreses números repetidos')
        .oneOf([pin], t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_CONFIRM.MATCH)
        .min(4, 'Ingresa 4 caracteres numéricos');
    }
    return schema;
  });

  return (
    <section className="flex flex-col items-center">
      <label className="mb-2 text-primary">
        <H3>Introduce tu código de seguridad</H3>
      </label>
      <Pin value={formik?.values?.pin ?? ''} onChange={formik?.handleChange('pin')} warning={formik?.errors.pin} />

      <label className="mb-2 mt-10 text-primary">
        <H3>Repite tu código de seguridad</H3>
      </label>
      <Pin
        value={formik?.values?.confirmPin ?? ''}
        onChange={formik?.handleChange('confirmPin')}
        warning={formik?.errors.confirmPin}
      />

      <PinValidation pinValue={formik?.values.pin ?? ''} />
      <H4 className="mb-4 mt-12 text-gray">Por seguridad, no uses tu año de nacimiento.</H4>
    </section>
  );
};
