import { AddFieldProps } from '../types';
import MaskedInput from 'react-text-mask';
import { createMask } from '../../../../utils/masks';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';

export const AliasField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              ALIAS {
                REQUIRED
                MIN_MAX
              }
            }
          }
          CVU {
            FORM {
              SUCCESS {
                ALIAS
              }
            }
          }
        }
      }
    }
  `);
  const aliasField = fields?.alias!;
  if (aliasField?.required || aliasField?.defaultValue) {
    schemas.alias = schemas?.alias
      .required(t(data).DYNAMIC_USER_FORM.FIELDS.ALIAS.REQUIRED)
      .min(6, t(data).DYNAMIC_USER_FORM.FIELDS.ALIAS.MIN_MAX)
      .max(20, t(data).DYNAMIC_USER_FORM.FIELDS.ALIAS.MIN_MAX)
      .matches(/^[a-zA-Z0-9.-]{6,20}$/);
  }

  return (
    <div key="alias" className="mb-5">
      <FieldLabel
        heading={t(data).CVU.FORM.SUCCESS.ALIAS}
        sideNote={t(data).DYNAMIC_USER_FORM.FIELDS.ALIAS.MIN_MAX}
        required={aliasField?.required}
      />
      <MaskedInput
        value={formik?.values.alias}
        onChange={formik?.handleChange('alias')}
        className={`input ${formik?.errors.alias && 'border-red'} ${aliasField?.readOnly && 'bg-gray-200'}`}
        mask={createMask('ALIAS', 20)}
        guide={false}
        disabled={aliasField?.readOnly}
      />
      {formik?.errors.alias && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.alias}
        </p>
      )}
    </div>
  );
};
