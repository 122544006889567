import React, { ReactNode } from 'react';
import { H5 } from '../../typography';

type FieldProps = {
  label?: string;
  required?: boolean;
  warning?: string;
  readOnly?: boolean;
  focused?: boolean;
  children?: ReactNode;
};

const Field = ({ label, required, warning, readOnly, focused, children }: FieldProps) => {
  let bg = 'bg-gray-100';
  let border = '';
  let outline = '';
  if (readOnly) {
    bg = 'bg-white';
    border = 'border border-gray-100';
  }
  if (warning) {
    bg = 'bg-red-50';
  }
  if (focused) {
    outline = 'outline outline-2 outline-cyan';
  }
  return (
    <div className="mb-2">
      <div
        className={`flex h-[52px] flex-col justify-center rounded-lg px-3 font-body focus-within:outline focus-within:outline-2 focus-within:outline-cyan ${bg} ${border} ${outline}`}
      >
        <label className="text-gray">
          <H5>
            <span>{label}</span>
            {required && <span>*</span>}
          </H5>
        </label>
        <div>{children}</div>
      </div>
      {warning && <span className="mt-1 block pl-1 text-xs text-red">{warning}</span>}
    </div>
  );
};

export { Field };
