import React from 'react';
import { H2, H3 } from '../../../typography';

const StepNormal = () => {
  return (
    <section className="mx-5 flex flex-col text-primary">
      <H2 className="mt-[60px] mb-5 text-center">Datos personales</H2>
      <H3 className="mb-5 text-center">Consulta y/o modifica tus datos personales</H3>
    </section>
  );
};

export { StepNormal };
